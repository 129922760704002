<template>
  <a-select
    v-model:value="selected"
    show-search
    :placeholder="placeholder"
    allowClear
    :disabled="disabled"
    :style="`width:${width};`"
    :default-active-first-option="false"
    :show-arrow="false"
    :filter-option="false"
    :not-found-content="null"
    :options="items"
    @search="getSearchFun"
    @change="setValueFun"
  ></a-select>
</template>
<script>
  import { defineComponent, onMounted, ref, watchEffect } from 'vue'
  import { API, requests } from '@/api/apis'
  export default defineComponent({
    props: {
      value: Number,
      labelname: String,
      disabled: {
        type: Boolean,
        default: false,
      },
      project: {
        type: Object,
      },
      placeholder: {
        type: String,
        default: '请选择项目',
      },
      width: {
        type: String,
        default: '200px',
      },
    },
    emits: ['update:value', 'update:labelname'],
    setup(props, ctx) {
      const items = ref([])
      const selected = ref()
      const getSearchFun = (key) => {
        requests({
          url: API.Projects.Select,
          data: { keyword: key },
        })
        .then((res) => {
            items.value = res.data
        })
        .catch((err) => {
            console.log(err, 'skk')
        })
      }
      watchEffect(() => {
        if (selected.value) {
          ctx.emit('update:value', selected.value)
        }
      }, [selected.value])
      watchEffect(() => {
        getSearchFun('')
      }, [])
      const setValueFun = (e) => {
        ctx.emit('update:value', undefined)
        console.log(e, '299')
      }
      return {
        getSearchFun,
        selected,
        items,
        setValueFun,
      }
    },
  })
</script>

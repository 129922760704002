<template>
  <a-form
    :label-col="formLayout.labelCol"
    :wrapper-col="formLayout.wrapperCol"
    v-bind="rformProps.modelRef"
    @submit="onSubmit"
  >
    <a-form-item label="项目编号：" name="code" v-bind="validateInfos['code']">
      <a-input type="text" placeholder="编号" v-model:value="model.code" />
    </a-form-item>
    <a-form-item label="项目名称：" name="name" v-bind="validateInfos['name']">
      <a-input type="text" placeholder="名称" v-model:value="model.name" />
    </a-form-item>

    <a-form-item
      label="创建时间："
      name="created_date"
      v-bind="validateInfos['created_date']"
    >
      <a-date-picker
        placeholder="日期选择"
        format="YYYY-MM-DD"
        @change="onchangeTime"
      />
    </a-form-item>
    <a-form-item label="历史修改金额：">
      <a-input
        prefix="￥"
        v-model:value="model.history_modify_amount"
        placeholder="金额"
        suffix="RMB"
      />
    </a-form-item>
    <a-form-item label="历史修改次数：">
      <a-input
        type="text"
        placeholder="次数"
        v-model:value="model.history_modify_count"
      />
    </a-form-item>
    <a-form-item label="项目开启：">
      <my-project-type v-model:value="model.status" />
    </a-form-item>

    <div style="padding: 20px 0; border-top: 1px solid #eee">
      <a-form-item label="合同金额：">
        <a-input
          v-model:value="model.contract_amount"
          addon-after="元"
          @change="ContractAmountFun"
        />
      </a-form-item>
      <div style="display: flex; line-height: 32px">
        <div style="display: flex">
          <div>黄色预警金额：</div>
          <div>
            <a-input
              style="width: 120px"
              @change="AmountFun(1)"
              v-model:value="model.yellow_alert_amount"
            />
          </div>
        </div>
        <div style="display: flex; padding-left: 10px">
          <div>黄色预警比例：</div>
          <div>
            <a-input
              @change="proportionFun(1)"
              v-model:value="model.yellow_alert_rate"
              addon-after="%"
            />
          </div>
        </div>
      </div>
      <div style="display: flex; line-height: 32px; padding: 20px 0">
        <div style="display: flex">
          <div>红色预警金额：</div>
          <div>
            <a-input
              style="width: 120px"
              @change="AmountFun(2)"
              v-model:value="model.red_alert_amount"
            />
          </div>
        </div>
        <div style="display: flex; padding-left: 10px">
          <div>红色预警比例：</div>
          <div>
            <a-input
              addon-after="%"
              @change="proportionFun(2)"
              v-model:value="model.red_alert_rate"
            />
          </div>
        </div>
      </div>
    </div>

    <a-form-item :wrapper-col="formLayout.submitWrapperCol">
      <a-button type="primary" html-type="submit">保存</a-button>
      <a-button style="margin-left: 10px" @click="$emit('closeModel')">
        关闭
      </a-button>
    </a-form-item>
  </a-form>
</template>
<script>
  import { reactive } from 'vue'
  import { notification } from 'ant-design-vue'
  import { API, requests } from '@/api/apis'
  import MyProjectType from '@/components/Radio/MyprojectType.vue'
  import { Form } from 'ant-design-vue'
  const useForm = Form.useForm
  export default {
    name: 'create_form',
    inject: ['formLayout'],
    emits: ['finish'],
    components: { MyProjectType },
    setup(props, context) {
      const modelRef = reactive({
        //表单
        created_date: undefined, //创建时间
        history_modify_amount: undefined, //修改金额
        history_modify_count: undefined, //修改次数
        name: undefined, //名称
        code: undefined, //编号
        status: '进行中', //状态
        contract_amount: undefined, //合同金额
        yellow_alert_amount: undefined,
        red_alert_amount: undefined,
        yellow_alert_rate: 30,
        red_alert_rate: 70,
      })
      const rulesRef = reactive({
        //校验
        name: [
          {
            required: true,
            type: 'string',
            message: '请设置名称！',
            trigger: 'change',
          },
        ],
        code: [
          {
            required: true,
            type: 'string',
            message: '请输入编号！',
            trigger: 'change',
          },
        ],
        created_date: [
          {
            required: true,
            type: 'string',
            message: '请设置时间！',
            trigger: 'change',
          },
        ],
      })

      const { validate, validateInfos } = useForm(modelRef, rulesRef)

      const onSubmit = (e) => {
        //提交
        e.preventDefault()
        validate()
          .then(() => {
            onSubmitForm()
          })
          .catch((err) => {
            console.log('error', err)
          })
      }

      const ContractAmountFun = (e) => {
        if (!modelRef.contract_amount) {
          return false
        }
        let numbers = parseFloat(modelRef.contract_amount)
        if (numbers) {
          modelRef.yellow_alert_amount = (
            numbers *
            (modelRef.yellow_alert_rate / 100)
          ).toFixed(2)
          modelRef.red_alert_amount = (
            numbers *
            (modelRef.red_alert_rate / 100)
          ).toFixed(2)
        }
        console.log(numbers, 'csd')
      }
      const AmountFun = (type) => {
        if (!modelRef.contract_amount) {
          return false
        }
        if (type == 1) {
          modelRef.yellow_alert_rate = (
            parseFloat(modelRef.yellow_alert_amount) /
            parseFloat(modelRef.contract_amount)
          ).toFixed(2)
        }
        if (type == 2) {
          modelRef.red_alert_rate = (
            parseFloat(modelRef.red_alert_amount) /
            parseFloat(modelRef.contract_amount)
          ).toFixed(2)
        }
      }
      const proportionFun = (type) => {
        if (!modelRef.contract_amount) {
          return false
        }
        let numbers = parseFloat(modelRef.contract_amount)
        if (type == 1) {
          modelRef.yellow_alert_amount = (
            numbers *
            (modelRef.yellow_alert_rate / 100)
          ).toFixed(2)
        }

        if (type == 2) {
          modelRef.red_alert_amount = (
            numbers *
            (modelRef.red_alert_rate / 100)
          ).toFixed(2)
        }
      }

      const onSubmitForm = () => {
        let data = JSON.parse(JSON.stringify(modelRef))
        data.status = data.status ? 1 : '0'
        requests({
          url: API.Projects.Store,
          data: data,
        })
          .then((res) => {
            notification['success']({
              message: '提示',
              description: '添加成功！',
            })
            context.emit('finish')
          })
          .catch((err) => {
            console.log(err)
          })
      }

      const rformProps = {
        modelRef,
        rulesRef,
      }
      const onchangeTime = (dates, dateStrings) => {
        modelRef.created_date = dateStrings
        console.log(dateStrings, '时间')
      }

      return {
        rformProps,
        model: modelRef,
        rules: rulesRef,
        validateInfos,
        onSubmit,
        onchangeTime,
        ContractAmountFun,
        AmountFun,
        proportionFun,
      }
    },
  }
</script>
